import { theme } from "@project/common"
import { Flex, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const StyledFlex = styled(Flex)`
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  #indicator__box {
    width: 28px;
    height: 28px;
    border-radius: 5px;
  }
  .accent {
    background-color: ${theme.colors.accent} !important;
  }
  .gray {
    background-color: ${theme.colors.border} !important;
  }
`
export const ApplicationManagementLegend = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <StyledFlex gap={16} wrap={"wrap"}>
      <Flex gap={8} wrap={"wrap"} align={"center"}>
        <div id={"indicator__box"} className={"accent"} />
        <Typography.Text>{t("Applied")}</Typography.Text>
      </Flex>
      <Flex gap={8} wrap={"wrap"} align={"center"}>
        <div id={"indicator__box"} className={"gray"} />
        <Typography.Text>{t("Cancel waiting")}</Typography.Text>
      </Flex>
    </StyledFlex>
  )
}
