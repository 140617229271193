import { theme } from "@project/common"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { TriangleBox } from "../../../../components"
import { Flex, Typography } from "antd"
import { useApplicationManagement } from "../../../../context/ApplicationManagementContext"
import dayjs from "dayjs"
const StyledWrapper = styled(Flex)`
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  border: 1px solid ${theme.colors.accent};
  padding: 16px;
  border-radius: 5px;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  .label__content {
    background-color: ${theme.colors.accent};
    padding: 12px;
    border-radius: 6px;
    min-width: 100px;
    min-height: 30px;
  }
`
export const ApplicationManagementSummaryInfo = (): JSX.Element => {
  const { t } = useTranslation()
  const { response, dataSource } = useApplicationManagement()
  const appliedNoWithNewlySelectedData = dataSource?.filter(
    (v) => v?.facility_id && v?.is_applied,
  ).length
  return (
    <StyledWrapper className={"summary"}>
      <TriangleBox
        label={t("Current Applied No.")}
        value={
          appliedNoWithNewlySelectedData ||
          response?.data?.stats?.application_count ||
          0
        }
      />
      <TriangleBox
        label={t("Contract amount")}
        value={`${
          response?.data?.stats?.facility_contract?.benefit_days || "0"
        }`}
      />
      <Flex gap={8} wrap={"wrap"} align={"center"}>
        <div className={"label__content"}>
          {response?.data?.stats?.facility_contract?.facility?.facility_name ||
            ""}
        </div>
        <div className={"facility_summary"}>
          <div className={"absent_info"}>
            <Typography.Text>{`${
              appliedNoWithNewlySelectedData ||
              response?.data?.stats?.application_count ||
              "0"
            }/${
              response?.data?.stats?.facility_contract?.benefit_days || "0"
            }`}</Typography.Text>{" "}
            {response?.data?.stats?.absent_days?.length > 0 && (
              <>
                {"("}
                <Typography.Text>
                  {t("Absent")} {" : "}
                </Typography.Text>
                {response?.data?.stats?.absent_days?.map((v, index) => (
                  <Typography.Text key={v}>
                    {dayjs(v)?.date()}
                    {t("Day")}
                    {index ===
                      response?.data?.stats?.absent_days?.length - 2 && (
                      <>{","}</>
                    )}
                  </Typography.Text>
                ))}
                {")"}
              </>
            )}
          </div>
          <Typography.Text>
            {t("Remaining Available date")}
            {" : "}
            {(appliedNoWithNewlySelectedData
              ? response?.data?.stats?.facility_contract?.benefit_days -
                appliedNoWithNewlySelectedData
              : response?.data?.stats?.remaining_days) || "0"}
          </Typography.Text>
        </div>
      </Flex>
    </StyledWrapper>
  )
}
