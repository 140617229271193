import {
  Button,
  CALENDAR_HOURS_MINUTES,
  CheckBox,
  InputAreaField,
  InputField,
  LOCATIONS as ORIGINAL_LOCATIONS,
  PrintFriendlyTable,
  Radio,
  SelectInput,
  Table,
  theme,
} from "@project/common"
import { Flex, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useApplicationManagement } from "../../../../../context/ApplicationManagementContext"
import { fetchAllShiftUserMaster } from "../../../../../services"

export const ApplicationManagementListTable = ({
  mode,
  page,
  isPrint = false,
}: {
  mode?: "EDIT" | "VIEW"
  page?: "render" | "print"
  isPrint?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const { dataSource, mealData, params } = useApplicationManagement()
  const { handleDataChange, contractFacilities } = useApplicationManagement()

  const { data: shiftData } = useQuery(
    ["attendance-shift", contractFacilities],
    () =>
      fetchAllShiftUserMaster({
        page: 1,
        pageSize: "Infinity",
        facilityIds: [contractFacilities?.[1]?.value],
      }),
    {
      keepPreviousData: false,
      select: ({ data }) => {
        let options = [{ label: "--", value: 0 }]
        options = options.concat(
          data?.map((shift) => ({
            label: shift?.attendance_shift_name,
            value: shift?.id?.toString(),
          })),
        )
        return options
      },
      enabled: !!contractFacilities?.[1]?.value,
    },
  )

  const registeredFacilityColumn = (align: any) => {
    if (mode === "EDIT") {
      return [
        {
          title: (
            <div style={{ whiteSpace: "nowrap" }}>
              {t("Registered facility")}
            </div>
          ),
          key: "facility_name",
          align,
          width: 200,
          children: contractFacilities
            ?.filter((v) => v?.value)
            ?.map((facility) => ({
              title: (
                <div style={{ whiteSpace: "nowrap" }}>
                  {facility?.label || ""}
                </div>
              ),
              key: "facility_name",
              align,
              width: 200,
              render: (row) => {
                return {
                  props: {
                    style: {
                      background:
                        row?.holiday === "1"
                          ? theme.colors["pink-light2"]
                          : row?.is_applied
                            ? theme.colors.accent2
                            : "",
                    },
                  },
                  children: (
                    <Flex justify={"center"}>
                      {row?.holiday === "1" ? (
                        t("holiday")
                      ) : (
                        <Radio
                          value={row?.facility_id || null}
                          checked={row?.facility_id && row?.is_applied}
                          onChange={() => {
                            handleDataChange({
                              date: row?.day,
                              key: "facility_id",
                              value: row?.facility_id,
                            })
                          }}
                        />
                      )}
                    </Flex>
                  ),
                }
              },
              onCell: (row) => {
                if (row?.holiday === "1") {
                  return {
                    colSpan: 12,
                  }
                }
                return {}
              },
            })),
        },
      ]
    }
    return [
      {
        title: (
          <div style={{ whiteSpace: "nowrap" }}>{t("facility name ")}</div>
        ),
        key: "facility_name",
        dataIndex: "facility_name",
        align,
        width: 200,
        render: (_, row) => {
          return {
            props: {
              style: {
                background:
                  row?.holiday === "1"
                    ? theme.colors["pink-light2"]
                    : row?.is_applied
                      ? theme.colors.accent2
                      : "",
              },
            },
            children: (
              <Flex justify={"center"}>
                {row?.holiday === "1"
                  ? t("holiday")
                  : row?.is_applied
                    ? row?.facility?.facility_name
                    : "" || ""}
              </Flex>
            ),
          }
        },
        onCell: (row) => {
          if (row?.holiday === "1") {
            return {
              colSpan: 11,
            }
          }
          return {}
        },
      },
    ]
  }
  const addLastCol = ({ align }: { align?: any }) => {
    if (mode === "EDIT") {
      return [
        {
          title: t("Cancel"),
          align: align,
          key: "12",
          width: 100,
          render: (row) => (
            <>
              <Button
                btnText={t("Cancel")}
                type={"link"}
                style={{
                  padding: 0,
                  textDecoration: "underline",
                }}
                onClick={() => {
                  handleDataChange({
                    date: row?.day,
                    key: "cancel",
                    value: false,
                  })
                }}
              />
            </>
          ),
          onCell: (row) => {
            if (row?.holiday === "1") {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ]
    }
    return []
  }

  const columns: ColumnsType<any> = [
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{t("Day")}</div>,
      key: "day",
      width: 30,
      align: "center",
      render: (record) => {
        if (!record.is_holiday) {
          return <div className={"working-cell"}>{record.day}</div>
        }
        return <div className={"holiday-cell"}>{record.day}</div>
      },
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{t("Day of week")}</div>,
      dataIndex: "week",
      key: "week",
      width: 40,
      align: "center",
      render: (val, row) => {
        return (
          <div
            style={{
              color:
                row?.week_day === 6
                  ? theme.colors.action
                  : row?.week_day === 0
                    ? theme.colors.error
                    : theme.colors.text,
            }}
          >
            {t(val)}
          </div>
        )
      },
    },
    ...registeredFacilityColumn("center"),
    {
      title: t("Shift"),
      align: "center",
      key: "2",
      dataIndex: "shift_id",
      width: 135,
      render: (shift, row) => {
        if (mode === "EDIT") {
          return (
            <Flex
              style={{
                textAlign: "left",
              }}
            >
              <SelectInput
                name={"shift"}
                width={"110px"}
                placeholder={"--"}
                options={shiftData || []}
                popupMatchSelectWidth={false}
                value={shift || null}
                disabled={!row?.facility_id || !row?.is_applied}
                onChange={(val) =>
                  handleDataChange({
                    date: row?.day,
                    key: "shift_id",
                    value: val,
                  })
                }
              />
            </Flex>
          )
        }
        return row?.attendance_shift?.attendance_shift_name || "--"
      },
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Meal "),
      align: "center",
      key: "3",
      width: 95,
      render: (row) => {
        if (mode === "EDIT") {
          return (
            <CheckBox
              label={""}
              noLabel
              value={row.meal_flag}
              checked={row?.meal_flag}
              disabled={!row?.facility_id && !row?.is_applied}
              onChange={({ target: { checked } }) =>
                handleDataChange({
                  date: row?.day,
                  key: "meal_flag",
                  value: checked,
                })
              }
            />
          )
        }
        return row?.meal_flag ? "◯" : "--"
      },
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Type of Meal"),
      align: "center",
      key: "4",
      width: 95,
      render: (row) => {
        if (mode === "EDIT") {
          return (
            <Flex
              style={{
                textAlign: "left",
              }}
            >
              <SelectInput
                name={"meal_id"}
                value={+row?.meal_id || null}
                placeholder={"--"}
                width={"86px"}
                options={[{ label: "--", value: null }].concat(mealData || [])}
                popupMatchSelectWidth={false}
                disabled={!row?.meal_flag}
                onChange={(value) =>
                  handleDataChange({
                    date: row?.day,
                    key: "meal_id",
                    value,
                  })
                }
              />
            </Flex>
          )
        }
        return row?.meal?.name || "--"
      },
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Pick up & Drop off"),
      align: "center",
      key: "picK_drop",
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
      children: [
        {
          title: t("Pick"),
          align: "center",
          key: "5",
          width: 82,
          render: (row) => {
            return {
              props: {
                style: {
                  background: row?.goto_meet_flag && theme.colors.accent2,
                },
              },
              children:
                mode === "EDIT" ? (
                  <CheckBox
                    label={""}
                    noLabel
                    value={row?.goto_meet_flag || null}
                    checked={row?.goto_meet_flag}
                    disabled={!row?.facility_id && !row?.is_applied}
                    onChange={({ target: { checked } }) => {
                      handleDataChange({
                        date: row?.day,
                        key: "goto_meet_flag",
                        value: checked,
                      })
                    }}
                  />
                ) : (
                  <Flex justify={"center"}>
                    {row?.goto_meet_flag ? (
                      <Radio
                        checked
                        noLabel
                        label={""}
                        style={{
                          pointerEvents: "none",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Flex>
                ),
            }
          },
          onCell: (row) => {
            if (row?.holiday === "1") {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          title: t("Drop"),
          align: "center",
          key: "6",
          width: 82,
          render: (row) => {
            return {
              props: {
                style: {
                  background: row?.take_flag && theme.colors.accent2,
                },
              },
              children:
                mode === "EDIT" ? (
                  <CheckBox
                    label={""}
                    noLabel
                    value={row?.take_flag || null}
                    checked={row?.take_flag}
                    disabled={!row?.facility_id && !row?.is_applied}
                    onChange={({ target: { checked } }) => {
                      handleDataChange({
                        date: row?.day,
                        key: "take_flag",
                        value: checked,
                      })
                    }}
                  />
                ) : (
                  <Flex justify={"center"}>
                    {" "}
                    {row?.take_flag ? (
                      <Radio
                        checked
                        label={""}
                        noLabel
                        style={{
                          pointerEvents: "none",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Flex>
                ),
            }
          },
          onCell: (row) => {
            if (row?.holiday === "1") {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ],
    },
    {
      title: t("Pick up & Drop Location"),
      align: "center",
      key: "location",
      width: 160,
      children: [
        {
          title: t("Pick"),
          align: "center",
          key: "7",
          width: 80,
          render: (row) => (
            <>
              {mode === "EDIT" ? (
                <Flex
                  vertical
                  gap={6}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <SelectInput
                    name={"goto_meet_place"}
                    value={+row?.goto_meet_place || null}
                    placeholder={"--"}
                    options={LOCATIONS || []}
                    width={"86px"}
                    popupMatchSelectWidth={false}
                    disabled={!row?.goto_meet_flag}
                    onChange={(value) => {
                      handleDataChange({
                        date: row?.day,
                        key: "goto_meet_place",
                        value: `${value}`,
                      })
                    }}
                  />
                  {row?.goto_meet_place === "2" && (
                    <InputField
                      value={row?.goto_meet_place_other || null}
                      placeholder={"--"}
                      height={"40px"}
                      onChange={({ target: { value } }) => {
                        handleDataChange({
                          date: row?.day,
                          key: "goto_meet_place_other",
                          value: value,
                        })
                      }}
                    />
                  )}
                </Flex>
              ) : (
                <Flex vertical gap={4}>
                  {LOCATIONS?.find((v) => v?.value === +row?.goto_meet_place)
                    ?.label || "--"}
                  {row?.goto_meet_place === "2" && (
                    <Typography.Text>
                      {row?.goto_meet_place_other}
                    </Typography.Text>
                  )}
                </Flex>
              )}
            </>
          ),
          onCell: (row) => {
            if (row?.holiday === "1") {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          title: t("Drop"),
          align: "center",
          key: "8",
          width: 80,
          render: (row) => (
            <>
              {mode === "EDIT" ? (
                <Flex
                  vertical
                  gap={6}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <SelectInput
                    name={"take_place"}
                    value={+row?.take_place || null}
                    placeholder={"--"}
                    options={LOCATIONS || []}
                    width={"86px"}
                    disabled={!row?.take_flag}
                    popupMatchSelectWidth={false}
                    onChange={(value) => {
                      handleDataChange({
                        date: row?.day,
                        key: "take_place",
                        value: `${value}`,
                      })
                    }}
                  />
                  {row?.take_place === "2" && (
                    <InputField
                      value={row?.take_place_other || null}
                      placeholder={"--"}
                      height={"40px"}
                      onChange={({ target: { value } }) => {
                        handleDataChange({
                          date: row?.day,
                          key: "take_place_other",
                          value: value,
                        })
                      }}
                    />
                  )}
                </Flex>
              ) : (
                <Flex vertical gap={4}>
                  {LOCATIONS?.find((v) => v?.value === +row?.take_place)
                    ?.label || "--"}
                  {row?.take_place === "2" && (
                    <Typography.Text>{row?.take_place_other}</Typography.Text>
                  )}
                </Flex>
              )}
            </>
          ),
          onCell: (row) => {
            if (row?.holiday === "1") {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ],
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Desired pick up time"),
      align: "center",
      key: "9",
      width: 95,
      render: (row) => (
        <>
          {mode === "EDIT" ? (
            <Flex vertical gap={8}>
              <Flex align={"center"} gap={4}>
                <SelectInput
                  name={"pickup_hr"}
                  width={"64px"}
                  value={row?.goto_meet_time?.split(":")?.[0] || null}
                  options={CALENDAR_HOURS_MINUTES.hours || []}
                  disabled={!row?.goto_meet_flag}
                  placeholder={"--"}
                  onChange={(value) => {
                    handleDataChange({
                      date: row?.day,
                      key: "goto_meet_time",
                      value: `${value}:00`,
                    })
                  }}
                />
                <Typography.Text>{":"}</Typography.Text>
              </Flex>

              <SelectInput
                name={"pickup_min"}
                width={"64px"}
                value={row?.goto_meet_time?.split(":")?.[1] || null}
                options={CALENDAR_HOURS_MINUTES.minutes || []}
                disabled={
                  row?.goto_meet_time?.split(":")?.[0]
                    ? false
                    : true || !row?.goto_meet_flag
                }
                placeholder={"--"}
                onChange={(value) => {
                  handleDataChange({
                    date: row?.day,
                    key: "goto_meet_time",
                    value: `${row?.goto_meet_time?.split(":")?.[0]}:${value}`,
                  })
                }}
              />
            </Flex>
          ) : row?.goto_meet_time ? (
            <Flex gap={4} justify={"center"} wrap={"nowrap"}>
              <Typography.Text>
                {row?.goto_meet_time?.split(":")?.[0]}
              </Typography.Text>
              <Typography.Text>{":"}</Typography.Text>
              <Typography.Text>
                {row?.goto_meet_time?.split(":")?.[1]}
              </Typography.Text>
            </Flex>
          ) : (
            "--"
          )}
        </>
      ),
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Desired drop off time"),
      align: "center",
      key: "10",
      width: 95,
      render: (row) => (
        <>
          {mode === "EDIT" ? (
            <Flex vertical gap={8}>
              <Flex align={"center"} gap={4}>
                <SelectInput
                  name={"dropoff_hr"}
                  width={"64px"}
                  value={row?.take_time?.split(":")?.[0] || null}
                  options={CALENDAR_HOURS_MINUTES?.hours || []}
                  placeholder={"--"}
                  disabled={!row?.take_flag}
                  onChange={(value) => {
                    handleDataChange({
                      date: row?.day,
                      key: "take_time",
                      value: `${value}:00`,
                    })
                  }}
                />
                <Typography.Text>{":"}</Typography.Text>
              </Flex>

              <SelectInput
                name={"dropoff_min"}
                width={"64px"}
                value={row?.take_time?.split(":")?.[1] || null}
                options={CALENDAR_HOURS_MINUTES.minutes || []}
                disabled={
                  row?.take_time?.split(":")?.[0]
                    ? false
                    : true || !row?.take_flag || !row?.facility_id
                }
                placeholder={"--"}
                onChange={(value) => {
                  handleDataChange({
                    date: row?.day,
                    key: "take_time",
                    value: `${row?.take_time?.split(":")?.[0]}:${value}`,
                  })
                }}
              />
            </Flex>
          ) : row?.take_time ? (
            <Flex gap={4} justify={"center"} wrap={"nowrap"}>
              <Typography.Text>
                {row?.take_time?.split(":")?.[0]}
              </Typography.Text>
              <Typography.Text>{":"}</Typography.Text>
              <Typography.Text>
                {row?.take_time?.split(":")?.[1]}
              </Typography.Text>
            </Flex>
          ) : (
            "--"
          )}
        </>
      ),
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Remark"),
      align: "center",
      key: "11",
      width: 100,
      render: (row) => (
        <>
          {mode === "EDIT" ? (
            <InputAreaField
              resize={false}
              rows={3}
              width={"176px"}
              value={row?.remarks || null}
              placeholder={""}
              disabled={!row?.facility_id && !row?.is_applied}
              onChange={({ target: { value } }) => {
                handleDataChange({
                  date: row?.day,
                  key: "remarks",
                  value: value,
                })
              }}
            />
          ) : (
            row?.remarks
          )}
        </>
      ),
      onCell: (row) => {
        if (row?.holiday === "1") {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    ...addLastCol({ align: "center" }),
  ]

  const filteredColumns = () => {
    if (page === "print" && params?.display_settings?.length) {
      const arry = columns?.slice(0, 3)
      const newArry = columns
        ?.slice(3, columns?.length)
        .map((col: any) =>
          col?.key === "picK_drop" &&
          (params?.display_settings?.includes("5") ||
            params?.display_settings?.includes("6"))
            ? {
                ...col,
                children: col.children.filter(
                  (child) => params?.display_settings?.includes(child.key),
                ),
              }
            : col?.key === "location" &&
                (params?.display_settings?.includes("7") ||
                  params?.display_settings?.includes("8"))
              ? {
                  ...col,
                  children: col.children.filter(
                    (child) => params?.display_settings?.includes(child.key),
                  ),
                }
              : params?.display_settings?.includes(col.key)
                ? col
                : null,
        )
        .filter(Boolean)
      return [...arry, ...newArry]
    }
    return columns
  }

  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }

  const LOCATIONS = getOptionsWithTranslatedLabel(ORIGINAL_LOCATIONS)

  return (
    <>
      {isPrint ? (
        <PrintFriendlyTable
          dataSource={dataSource}
          columns={filteredColumns()}
          headerBg={theme.colors["t-head"]}
          border={`1px solid ${theme.colors.border}`}
        />
      ) : (
        <Table
          columns={filteredColumns()}
          dataSource={dataSource}
          scroll={{ x: true }}
        />
      )}
    </>
  )
}
